.root {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--surface-900);

  a {
    color: var(--primary);
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--default-color);
    margin-bottom: 12px;
  }

  h2,
  :global(.h2) {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 36px;
  }

  h3,
  :global(.h3) {
    font-weight: 600;
    margin-bottom: 12px;
    font-size: 1.5rem;
    margin-top: 12px;
  }

  h4,
  :global(.h4) {
    font-weight: 600;
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 0;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  ul,
  ol {
    margin: 0;
    list-style: none;

    + p {
      margin-top: 0;
    }

    li {
      position: relative;
      align-items: baseline;
    }
  }

  ul {
    padding-left: 0.6em;

    li {
      padding-left: 0.6em;

      &:before {
        content: '';
        display: block;
        background-color: var(--default-color);
        width: 2px;
        height: 2px;
        border-radius: 1px;
        position: absolute;
        left: 0;
        top: 0.725em;
      }
    }
  }

  ol {
    padding-left: 0.6em;
    counter-reset: policy-counter;

    li {
      counter-increment: policy-counter;
      display: flex;

      &::before {
        content: counter(policy-counter) '.';
        display: block;
        font-weight: bold;
        padding-right: 0.38em;
        line-height: 1;
      }
    }
  }

  :global {
    .spacing-top-md {
      margin-top: 24px;
    }

    .spacing-top-lg {
      margin-top: 36px;
    }

    .spacing-top-0 {
      margin-top: 0;
    }

    .spacing-top {
      margin-top: 12px;
    }

    .semi-bold {
      font-weight: 600;
    }

    .quote {
      color: var(--default-color);
      line-height: 1.5;
      font-style: italic;
      font-family: Inter;
      font-size: 0.875rem;
      margin: 12px 0;
      font-weight: 400;
    }

    .table {
    }

    .tableRow {
      display: flex;
      padding-top: 12px;
      box-shadow: 0 -2px 0 0 #dedede;
      margin-top: 14px;
    }

    .tableColumn {
      padding: 0 20px;
      flex: 1;
      width: 100%;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &:nth-child(1) {
        max-width: 290px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .root {
    :global {
      .break-line {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 844px) {
  .root {
    :global {
      .tableRow {
        display: flex;
        flex-direction: column;
      }

      .tableColumn {
        padding: 0;
      }
    }
  }
}
