.about {
  padding: 106px 0 130px 0;
  background-color: #fff;
  border-radius: 0 0 0 160px;
  box-shadow: 0 -100px 0 0 #fff, 0 4px 30px 0 #57575733;
}

.about__title {
  font-family: var(--font-secondary);
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--default-color);
}

.about__text {
  font-family: var(--font);
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.01em;
  color: var(--default-color);
}

.about__text + .about__text {
  margin-top: 12px;
}

.about__people {
  margin-top: 84px;
}

.about__subtitle {
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: var(--default-color);
  text-transform: uppercase;
}

.about__founder-title {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: var(--font);
  font-size: 30px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: -0.01em;
  color: var(--default-color);
}

.about__linkedin {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 12px;
}

.about-horizontal__linkedin {
  margin-left: 4px;
}

.about__linkedin svg {
  display: block;
  width: 18px;
  height: 18px;
}

.about__founder-image {
  display: block;
  margin: 0 auto 12px;
  width: 100%;
  max-width: 108px;
  flex-shrink: 0;
}

.about__founder-position {
  font-family: var(--font);
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.01em;
  color: var(--default-color);
}

.about__wallet-icon {
  display: inline-block;
  vertical-align: bottom;
  width: 1.286em;
  height: 1em;
  position: relative;
}

.about__wallet-icon img {
  display: block;
  width: 1.286em;
  height: 1.286em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about__text--no-wrap {
  white-space: nowrap;
  display: block;
}

.about__wallet-icon--mobile {
  display: none;
}

.row.about__founders {
  margin: 0 0;
  display: flex;
  gap: 48px;
}

.row.about__founders > * {
  width: initial;
  flex: 0 auto;
}

.row.about__founders > .col {
  box-sizing: border-box;
  padding: 0 0;
  flex-basis: 46%;
}

.about__founder-tile {
  min-width: 330px;
  width: 100%;
  text-align: center;
}

.about__ticketmeta-logo {
  display: inline-block;
  width: 1.143em;
  height: 1em;
  position: relative;
  vertical-align: bottom;
}

.about__ticketmeta-logo svg {
  position: absolute;
  width: 1.143em;
  height: 1.262em;
  top: 50%;
  display: block;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1375px) {
  .about__text--no-wrap {
    white-space: initial;
    display: initial;
  }
}

@media screen and (max-width: 1195px) {

  .row.about__founders > .col {
    box-sizing: border-box;
    padding: 0 0;
    flex-basis: 100%;
  }

  .about__subtitle {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 844px) {
  .about {
    padding: 60px 0;
    border-radius: 0;
  }

  .about .row {
    flex-direction: column;
  }

  .about__in > .row > .col {
    width: 100%;
    box-sizing: border-box;
  }

  .about__title {
    margin-bottom: 36px;
    font-size: 28px;
    line-height: 28px;
  }

  .about__text {
    font-size: 24px;
    line-height: 28.8px;
  }

  .about__people {
    margin-top: 60px;
  }

  .about__subtitle {
    font-size: 28px;
    line-height: 33.6px;
  }

  .about__founders {
    align-items: center;
  }

  .about__wallet-icon {
    width: 1.5em;
  }

  .about__wallet-icon img {
    width: 1.5em;
    height: 1.5em;
  }

  .about__ticketmeta-logo {
    width: 1.96em;
  }

  .about__ticketmeta-logo svg {
    width: 1.96em;
    height: 1.25em;
  }

  .about__wallet-icon--mobile {
    display: inline-block;
  }

  .about__wallet-icon--desktop {
    display: none;
  }

  .about__founders.row {
    flex-direction: row;
  }
}

@media screen and (max-width: 800px) {
  .about {
    padding: 60px 0 36px 0;
  }

  .about__founders.row {
    flex-direction: column;
    margin: 0 -24px;
  }

  .row.about__founders > .col {
    width: 100%;
    flex: 0 100%;
    padding: 0 24px;
  }

  .about__founder-tile {
    min-width: auto;
    max-width: 330px;
    margin: 0 auto 24px auto;
  }
}

.about-tile-horizontal__title {
  font-family: var(--font-secondary);
  font-size: 18px;
  letter-spacing: -0.03em;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}

.about-tile-horizontal__position {
  font-family: var(--font-secondary);
  color: var(--primary);
  font-size: 16px;
  letter-spacing: -0.48px;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  gap: 2px;
}

.about-tile-horizontal__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.about-tile-horizontal__description {
  color: var(--default-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.16px;
}