.tile {
  position: relative;
}

.tile:not(.tile--variant-g):before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--default-color);
  border: 1px solid #fff;
  border-radius: 24px;
  transform-origin: 0 0;
  box-shadow: 0 0 0 4px #f3f4f7, 0 0 0 1px #e4e5e999, 0 0 0 0.5px #e4e5e9;
}

.tile--variant-a:before {
  top: 5px;
  width: 89%;
  height: 104%;
  transform: rotate(-2.86deg);
}

.tile--variant-b:before {
  top: -5px;
  left: 50px;
  width: 89%;
  height: 87%;
  transform: rotate(2.72deg);
  transform-origin: 100% 100%;
}

.tile--variant-c:before {
  top: -16px;
  left: -7px;
  width: 98%;
  height: 102%;
  transform: rotate(-1.87deg);
  transform-origin: 50% 50%;
}

.tile--variant-d:before {
  top: 5px;
  left: 10px;
  width: 95%;
  height: 101%;
  transform: rotate(3.02deg);
  transform-origin: 100% 100%;
}

.tile--variant-e:before {
  top: 3px;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(2.72deg);
  transform-origin: 100% 100%;
}

.tile--variant-f:before {
  top: 18px;
  left: -3px;
  width: 100%;
  height: 97.9%;
  transform: rotate(3.02deg);
  transform-origin: 100% 100%;
}

.tile__in {
  position: relative;
  z-index: 1;
  padding: 24px;
  font-size: 24px;
  border: 3px solid var(--default-color);
  border-radius: 24px;
  background-color: #fff;
}

.tile--variant-g .tile__surface {
  position: relative;
  background-color: #fff;
  display: flex;
  width: 100%;
  max-width: 450px;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  z-index: 1;
}

.tile--variant-e .tile__in,
.tile--variant-f .tile__in {
  background: linear-gradient(
    137.95deg,
    #7892b0 2.28%,
    #eaeff3 19.8%,
    #c2d4e1 32.94%,
    #ffffff 50.16%,
    #d4dee5 62.15%,
    #abbdc8 78.69%,
    #bccad7 95.24%
  );
}

.tile__header {
  margin-bottom: 12px;
}

.tile__title {
  display: inline-flex;
  position: relative;
  padding: 6px 12px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  color: #fff;
  background-color: var(--primary);
  text-transform: uppercase;
  border: 1px solid #b8c9d3;
  border-radius: 40px;
}

.tile__text {
  margin-bottom: 24px;
}

.tile__image {
  max-width: 100%;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 844px) {
  .tile:before {
    border-width: 0;
  }

  .tile--variant-a:before {
    height: 101%;
  }

  .tile--variant-b:before {
    top: 5px;
    left: 25px;
  }
}
