.footer-menu {
  justify-content: flex-end;
  max-width: 893px;
  width: 100%;
}

.footer-menu__list {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.footer-menu__get-in-touch {
  display: none;
  color: var(--default-color);
  font-family: var(--font-secondary);
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.footer-menu__item {
  margin-left: 36px;
}

.footer-menu__item:first-child {
  margin-left: 0;
}

.footer-menu__link {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--default-color);
}

.footer-menu__contacts .footer-menu__link {
  text-decoration: underline;
}

.footer-menu__social {
  margin-left: auto;
}

.footer-menu__social .footer-menu__item {
  margin-left: 16px;
}

.footer-menu__social .footer-menu__item:first-child {
  margin-left: 0;
}

.footer-menu__social .footer-menu__link {
  display: block;
  width: 24px;
  height: 24px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1081px) {
  .footer-menu__links {
    padding: 0;
  }

  .footer-menu__contacts {
    margin-left: 36px;
    padding: 0;
  }
}

@media screen and (max-width: 1080px) {
  .footer-menu {
    flex-direction: column-reverse;
    text-align: left;
    justify-content: flex-start;
    margin-top: 36px;
  }

  .footer-menu__contacts {
    order: 1;
    margin-left: 0;
    margin-bottom: 24px;
  }

  .footer-menu__social {
    margin-left: 0;
    margin-bottom: 40px;
  }

  .footer-menu__get-in-touch {
    display: block;
  }

  .footer-menu__item {
    margin-left: 24px;
  }
}

@media screen and (max-width: 844px) {
  .footer-menu__link {
    font-size: 14px;
  }

  .footer-menu__link-email {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.18px;
  }
}
