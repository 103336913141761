.formGroup {
  .case {
    min-height: 96px;
  }
}

.formGroup {
  margin-bottom: 24px;
}

.submitButton {
  margin-top: 12px;
  width: 100%;
}

@media screen and (max-width: 844px) {
  .formGroup {
    .case {
      min-height: 160px;
    }
  }
}
