@import './vars.scss';

@import '~bootstrap/scss/mixins/_banner.scss';
@include bsBanner('');

// scss-docs-start import-stack
// Configuration
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_variables-dark';
@import '~bootstrap/scss/_maps';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_utilities';

// Layout & components
@import '~bootstrap/scss/_root';
@import '~bootstrap/scss/_reboot';
@import '~bootstrap/scss/_type';
@import '~bootstrap/scss/_images';
// @import '~bootstrap/scss/_containers';
// @import '~bootstrap/scss/_grid';
// @import '~bootstrap/scss/_tables';
@import '~bootstrap/scss/_forms';
@import '~bootstrap/scss/_buttons';
@import '~bootstrap/scss/_transitions';
// @import '~bootstrap/scss/_dropdown';
@import '~bootstrap/scss/_button-group';
// @import '~bootstrap/scss/_nav';
// @import '~bootstrap/scss/_navbar';
// @import '~bootstrap/scss/_card';
@import '~bootstrap/scss/_accordion';
// @import '~bootstrap/scss/_breadcrumb';
// @import '~bootstrap/scss/_pagination';
// @import '~bootstrap/scss/_badge';
// @import '~bootstrap/scss/_alert';
@import '~bootstrap/scss/_progress';
// @import '~bootstrap/scss/_list-group';
@import '~bootstrap/scss/_close';
// @import '~bootstrap/scss/_toasts';
@import '~bootstrap/scss/_modal';
// @import '~bootstrap/scss/_tooltip';
// @import '~bootstrap/scss/_popover';
// @import '~bootstrap/scss/_carousel';
@import '~bootstrap/scss/_spinners';
// @import '~bootstrap/scss/_offcanvas';
// @import '~bootstrap/scss/_placeholders';

// Helpers
@import '~bootstrap/scss/_helpers';

// Utilities
@import '~bootstrap/scss/utilities/_api';
// scss-docs-end import-stack

.modal-backdrop.show {
  --bs-backdrop-opacity: 0.7;
}

.form-label {
  color: var(--default-color);
  font-family: var(--font);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
}

.form-control {
  color: var(--default-color);
  font-family: var(--font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  padding: 12px 16px;

  border-radius: var(--Main-System-12px, 12px);
  background: var(--Grayscale-50, #fafafa);

  &:not(.is-invalid) {
    border: 1px solid var(--Grayscale-100, #f5f5f5);
  }

  &::-webkit-input-placeholder {
    color: var(--Gray-400, #a3a3a3);
  }
  &::-moz-placeholder {
    color: var(--Gray-400, #a3a3a3);
  }
  &:-ms-input-placeholder {
    color: var(--Gray-400, #a3a3a3);
  }
  &:-moz-placeholder {
    color: var(--Gray-400, #a3a3a3);
  }
}

.accordion {
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem #{transparentize(
      $landing-primary-color,
      0.75
    )};
}

@media screen and (max-width: 844px) {
  .form-control {
    padding: 12px;
  }
}
