.footer {
  padding-top: 60px !important;
  padding-bottom: 20px !important;
}

.footer__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer__copyright {
  font-size: 18px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  color: var(--default-color);
}

.footer__button {
  transform: translateX(13px);
  min-width: 180px;
}

@media screen and (max-width: 1080px) {
  .footer > .row {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__right {
    align-items: flex-start;
  }

  .footer__copyright,
  .footer__button {
    display: none;
  }
}

@media screen and (max-width: 844px) {
  .footer {
    display: block !important;
    padding: 48px 24px 22px !important;
  }
}
