.header {
  padding-top: 16px;
  padding-bottom: 16px;
}

.header__main-menu-button {
  display: none;
}

.header__logo svg {
  display: block;
}

@media screen and (max-width: 900px) {
  .header {
    padding: 11px 24px !important;
  }

  .header__main-menu {
    display: none;
  }

  .header__main-menu-button {
    display: initial;
    padding-left: 12px;
  }

  .header__logo svg {
    width: 40px;
    height: 25px;
  }
}
