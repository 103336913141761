.root {
  padding: 10px 9.5px;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: var(--Themes-Blue-100);
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  span {
    background-color: var(--default-color);
    height: 2px;
    margin-bottom: 4px;
    background-color: #4a4b57;
    border-radius: 0px;

    &:nth-child(1) {
      width: 8px;
      transition: transform 0.75s ease-in-out, opacity 0.5s 0.25s ease-in-out,
        background-color 0.5s 0.25s ease-in-out,
        border-radius 0.5s 0.25s ease-in-out;
    }

    &:nth-child(2) {
      width: 15px;
      transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6),
        background-color 0.4s ease-in-out, border-radius 0.4s ease-in-out;
    }

    &:nth-child(3) {
      width: 15px;
      position: absolute;
      border-radius: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
      transition: transform 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6),
        opacity 0.4s ease-in-out, background-color 0.4s ease-in-out,
        border-radius 0.4s ease-in-out;
    }

    &:nth-child(4) {
      width: 12px;
      margin-bottom: 0;
      transition: transform 0.5s 0.125s ease-in-out,
        opacity 0.5s 0.25s ease-in-out, background-color 0.5s 0.25s ease-in-out,
        border-radius 0.5s 0.25s ease-in-out;
    }
  }
}

.open {
  .icon {
    span {
      &:nth-child(1) {
        transform: translateX(100%);
        opacity: 0;
        transition: transform 0.75s ease-in-out, opacity 0.5s 0.25s ease-in-out,
          background-color 0.5s 0.25s ease-in-out,
          border-radius 0.5s 0.25s ease-in-out;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
        transition: transform 0.4s 0.275s cubic-bezier(0.68, -0.6, 0.32, 1.6),
          background-color 0.4s 0.275s ease-in-out,
          border-radius 0.4s 0.275s ease-in-out;
        border-radius: 8px;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        transition: transform 0.4s 0.275s cubic-bezier(0.68, -0.6, 0.32, 1.6),
          opacity 0.4s 0.275s ease-in-out,
          background-color 0.4s 0.275s ease-in-out,
          border-radius 0.4s 0.275s ease-in-out;
        opacity: 1;
        border-radius: 8px;
      }

      &:nth-child(4) {
        width: 12px;
        margin-bottom: 0;
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 0.5s 0.125s ease-in-out,
          opacity 0.5s 0.25s ease-in-out,
          background-color 0.5s 0.25s ease-in-out,
          border-radius 0.5s 0.25s ease-in-out;
      }
    }
  }
}
