.discover {
  background: linear-gradient(
    137.95deg,
    #7892b0 2.28%,
    #eaeff3 19.8%,
    #c2d4e1 32.94%,
    #ffffff 50.16%,
    #d4dee5 62.15%,
    #abbdc8 78.69%,
    #bccad7 95.24%
  );
  padding: 96px 0 120px 0;
  border-radius: 160px 0 0 160px;
  box-shadow: 0px 4px 54px 0px #5757574d;
}

.discover__title {
  margin-bottom: 52px;
  font-family: var(--font-secondary);
  font-size: 48px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.03em;
  text-align: center;
  text-transform: uppercase;
  color: var(--default-color);
}

.discover__content {
  display: flex;
  flex-wrap: wrap;
  max-width: 1263px;
  padding: 0 12px;
  gap: 4%;
  box-sizing: border-box;
}

.discover__item {
  flex-basis: 48%;
  height: 100%;
  color: var(--default-color);
}

.discover__item:last-child {
  align-self: flex-end;
}

.discover__item:nth-child(2) {
  padding-top: 200px;
}

.discover__item:nth-child(3) {
  margin-top: -158px;
}

.discover__item-image-mobile {
  display: none;
}

.discover__item .tile__title {
  margin-bottom: 0;
}

.discover__item .tile__text {
  line-height: 1.3;
}

.discover__title-brake--mobile {
  display: none;
}

@media screen and (max-width: 1280px) {
  .discover__item .tile__text {
    br {
      display: none;
    }
  }
}

@media screen and (max-width: 844px) {
  .discover {
    padding: 48px 24px;
    border-radius: 50px 0 0 50px;
  }

  .discover__title {
    font-size: 24px;
    line-height: 28.8px;
    margin-bottom: 24px;
  }

  .discover .container {
    padding: 0;
  }

  .discover__item {
    margin-bottom: 24px;

    .tile__in {
      padding: 12px;
    }

    .tile__header {
      margin-bottom: 3px;
    }

    .tile__text {
      font-family: var(--font);
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 12px;
    }
  }

  .discover__item:nth-child(2) {
    padding-top: 0;
  }

  .discover__item:nth-child(3) {
    margin-top: 0;
  }

  .discover__item-image-desktop {
    display: none;
  }

  .discover__item-image-mobile {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .discover__content {
    flex-direction: column;
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  .discover__title {
    margin-left: -12px;
    margin-right: -12px;
  }

  .discover__title .d-block {
    display: initial !important;
  }

  .discover__title-brake--mobile {
    display: initial;
  }
}
