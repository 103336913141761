@import './vars.scss';

@font-face {
  font-family: 'Retro Banker';
  src: url(./fonts/RetroBanker.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --font: 'Darker Grotesque', sans-serif;
  --font-secondary: 'Retro Banker', sans-serif;

  --col-gap: 6px;
  --space: 4px;

  --default-color: #{$landing-default-color};
  --default-color-hover: #{$landing-default-color-hover};
  --primary: #{$landing-primary-color};
  --primary-hover: #{$landing-default-color-hover};
  --primary--button-bg: #{$landing-primary-button-bg};
  --primary--button-hover-bg: #{$landing-primary-button-hover-bg};
  --primary--button-hover-shadow: #{$landing-primary-button-hover-shadow};
  --Themes-Blue-100: #d0e1fd;

  --surface-900: #{$surface-900};
}

* {
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
}

body {
  font-family: 'Darker Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  background-color: #fff;
  overflow-x: hidden;
  color: var(--default-color);
  font-variant-numeric: tabular-nums;
}

.heading--primary {
  font-family: var(--font-secondary);
  color: var(--primary);
}

.heading--secondary {
  font-family: var(--font-secondary);
  text-transform: uppercase;
  color: var(--default-color);
}

.subtitle {
  font-size: 1.5rem;
  font-family: var(--font);
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 0;
}

h1,
.h1 {
  font-size: 2.25rem;
  margin-bottom: 0;
}

h2,
.h2 {
  font-size: 2.625rem;
  margin-bottom: 0;
}

h3,
.h3 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

h4,
.h4 {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0;
}

@media screen and (max-width: 1000px) {
  .subtitle {
    font-size: 1.125rem;
  }

  h1,
  .h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  h2,
  .h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.label-required {
  color: var(--primary);
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-block {
  display: block;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.container > .row {
  margin-right: calc((-1) * var(--col-gap) * 2);
  margin-left: calc((-1) * var(--col-gap) * 2);
}

.container > .row > .col,
[class^='col-'] {
  padding-right: calc(var(--col-gap) * 2);
  padding-left: calc(var(--col-gap) * 2);
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-baseline {
  align-items: baseline;
}

.align-self-baseline {
  align-self: baseline;
}

.align-start {
  align-items: start;
}

.align-self-start {
  align-self: start;
}

.align-end {
  align-items: end;
}

.align-self-end {
  align-self: end;
}

@media (max-width: 844px) {
  .container {
    padding: 0 24px;
  }

  .d-mobile-none {
    display: none;
  }
}

@media screen and (max-width: 1435px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc((-1) * var(--col-gap));
  margin-left: calc((-1) * var(--col-gap));
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0 0%;
}

.col,
[class^='col-'] {
  padding-right: var(--col-gap);
  padding-left: var(--col-gap);
  box-sizing: border-box;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 1);
}

.col-2 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 2);
}

.col-3 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 3);
}

.col-4 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 4);
}

.col-5 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 5);
}

.col-6 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 6);
}

.col-7 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 7);
}

.col-8 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 8);
}

.col-9 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 9);
}

.col-10 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 10);
}

.col-11 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 11);
}

.col-12 {
  flex: 0 0 auto;
  width: calc((100% / 12) * 12);
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.mt-1 {
  margin-top: var(--space) !important;
}

.pt-1 {
  padding-top: var(--space) !important;
}

.mr-1 {
  margin-right: var(--space) !important;
}

.pr-1 {
  padding-right: var(--space) !important;
}

.mb-1 {
  margin-bottom: var(--space) !important;
}

.pb-1 {
  padding-bottom: var(--space) !important;
}

.ml-1 {
  margin-left: var(--space) !important;
}

.pl-1 {
  padding-left: var(--space) !important;
}

.mx-1 {
  margin-right: var(--space) !important;
  margin-left: var(--space) !important;
}

.my-1 {
  margin-top: var(--space) !important;
  margin-bottom: var(--space) !important;
}

.m-1 {
  margin: var(--space) !important;
}

.px-1 {
  padding-right: var(--space) !important;
  padding-left: var(--space) !important;
}

.py-1 {
  padding-top: var(--space) !important;
  padding-bottom: var(--space) !important;
}

.p-1 {
  padding: var(--space) !important;
}

.mt-2 {
  margin-top: calc(var(--space) * 2) !important;
}

.pt-2 {
  padding-top: calc(var(--space) * 2) !important;
}

.mr-2 {
  margin-right: calc(var(--space) * 2) !important;
}

.pr-2 {
  padding-right: calc(var(--space) * 2) !important;
}

.mb-2 {
  margin-bottom: calc(var(--space) * 2) !important;
}

.pb-2 {
  padding-bottom: calc(var(--space) * 2) !important;
}

.ml-2 {
  margin-left: calc(var(--space) * 2) !important;
}

.pl-2 {
  padding-left: calc(var(--space) * 2) !important;
}

.mx-2 {
  margin-right: calc(var(--space) * 2) !important;
  margin-left: calc(var(--space) * 2) !important;
}

.my-2 {
  margin-top: calc(var(--space) * 2) !important;
  margin-bottom: calc(var(--space) * 2) !important;
}

.m-2 {
  margin: calc(var(--space) * 2) !important;
}

.px-2 {
  padding-right: calc(var(--space) * 2) !important;
  padding-left: calc(var(--space) * 2) !important;
}

.py-2 {
  padding-top: calc(var(--space) * 2) !important;
  padding-bottom: calc(var(--space) * 2) !important;
}

.p-2 {
  padding: calc(var(--space) * 2) !important;
}

.mt-3 {
  margin-top: calc(var(--space) * 3) !important;
}

.pt-3 {
  padding-top: calc(var(--space) * 3) !important;
}

.mr-3 {
  margin-right: calc(var(--space) * 3) !important;
}

.pr-3 {
  padding-right: calc(var(--space) * 3) !important;
}

.mb-3 {
  margin-bottom: calc(var(--space) * 3) !important;
}

.pb-3 {
  padding-bottom: calc(var(--space) * 3) !important;
}

.ml-3 {
  margin-left: calc(var(--space) * 3) !important;
}

.pl-3 {
  padding-left: calc(var(--space) * 3) !important;
}

.mx-3 {
  margin-right: calc(var(--space) * 3) !important;
  margin-left: calc(var(--space) * 3) !important;
}

.my-3 {
  margin-top: calc(var(--space) * 3) !important;
  margin-bottom: calc(var(--space) * 3) !important;
}

.m-3 {
  margin: calc(var(--space) * 3) !important;
}

.px-3 {
  padding-right: calc(var(--space) * 3) !important;
  padding-left: calc(var(--space) * 3) !important;
}

.py-3 {
  padding-top: calc(var(--space) * 3) !important;
  padding-bottom: calc(var(--space) * 3) !important;
}

.p-3 {
  padding: calc(var(--space) * 3) !important;
}

.mt-4 {
  margin-top: calc(var(--space) * 4) !important;
}

.pt-4 {
  padding-top: calc(var(--space) * 4) !important;
}

.mr-4 {
  margin-right: calc(var(--space) * 4) !important;
}

.pr-4 {
  padding-right: calc(var(--space) * 4) !important;
}

.mb-4 {
  margin-bottom: calc(var(--space) * 4) !important;
}

.pb-4 {
  padding-bottom: calc(var(--space) * 4) !important;
}

.ml-4 {
  margin-left: calc(var(--space) * 4) !important;
}

.pl-4 {
  padding-left: calc(var(--space) * 4) !important;
}

.mx-4 {
  margin-right: calc(var(--space) * 4) !important;
  margin-left: calc(var(--space) * 4) !important;
}

.my-4 {
  margin-top: calc(var(--space) * 4) !important;
  margin-bottom: calc(var(--space) * 4) !important;
}

.m-4 {
  margin: calc(var(--space) * 4) !important;
}

.px-4 {
  padding-right: calc(var(--space) * 4) !important;
  padding-left: calc(var(--space) * 4) !important;
}

.py-4 {
  padding-top: calc(var(--space) * 4) !important;
  padding-bottom: calc(var(--space) * 4) !important;
}

.p-4 {
  padding: calc(var(--space) * 4) !important;
}

.mt-5 {
  margin-top: calc(var(--space) * 5) !important;
}

.pt-5 {
  padding-top: calc(var(--space) * 5) !important;
}

.mr-5 {
  margin-right: calc(var(--space) * 5) !important;
}

.pr-5 {
  padding-right: calc(var(--space) * 5) !important;
}

.mb-5 {
  margin-bottom: calc(var(--space) * 5) !important;
}

.pb-5 {
  padding-bottom: calc(var(--space) * 5) !important;
}

.ml-5 {
  margin-left: calc(var(--space) * 5) !important;
}

.pl-5 {
  padding-left: calc(var(--space) * 5) !important;
}

.mx-5 {
  margin-right: calc(var(--space) * 5) !important;
  margin-left: calc(var(--space) * 5) !important;
}

.my-5 {
  margin-top: calc(var(--space) * 5) !important;
  margin-bottom: calc(var(--space) * 5) !important;
}

.m-5 {
  margin: calc(var(--space) * 5) !important;
}

.px-5 {
  padding-right: calc(var(--space) * 5) !important;
  padding-left: calc(var(--space) * 5) !important;
}

.py-5 {
  padding-top: calc(var(--space) * 5) !important;
  padding-bottom: calc(var(--space) * 5) !important;
}

.p-5 {
  padding: calc(var(--space) * 5) !important;
}

.mt-6 {
  margin-top: calc(var(--space) * 6) !important;
}

.pt-6 {
  padding-top: calc(var(--space) * 6) !important;
}

.mr-6 {
  margin-right: calc(var(--space) * 6) !important;
}

.pr-6 {
  padding-right: calc(var(--space) * 6) !important;
}

.mb-6 {
  margin-bottom: calc(var(--space) * 6) !important;
}

.pb-6 {
  padding-bottom: calc(var(--space) * 6) !important;
}

.ml-6 {
  margin-left: calc(var(--space) * 6) !important;
}

.pl-6 {
  padding-left: calc(var(--space) * 6) !important;
}

.mx-6 {
  margin-right: calc(var(--space) * 6) !important;
  margin-left: calc(var(--space) * 6) !important;
}

.my-6 {
  margin-top: calc(var(--space) * 6) !important;
  margin-bottom: calc(var(--space) * 6) !important;
}

.m-6 {
  margin: calc(var(--space) * 6) !important;
}

.px-6 {
  padding-right: calc(var(--space) * 6) !important;
  padding-left: calc(var(--space) * 6) !important;
}

.py-6 {
  padding-top: calc(var(--space) * 6) !important;
  padding-bottom: calc(var(--space) * 6) !important;
}

.p-6 {
  padding: calc(var(--space) * 6) !important;
}

.mt-7 {
  margin-top: calc(var(--space) * 7) !important;
}

.pt-7 {
  padding-top: calc(var(--space) * 7) !important;
}

.mr-7 {
  margin-right: calc(var(--space) * 7) !important;
}

.pr-7 {
  padding-right: calc(var(--space) * 7) !important;
}

.mb-7 {
  margin-bottom: calc(var(--space) * 7) !important;
}

.pb-7 {
  padding-bottom: calc(var(--space) * 7) !important;
}

.ml-7 {
  margin-left: calc(var(--space) * 7) !important;
}

.pl-7 {
  padding-left: calc(var(--space) * 7) !important;
}

.mx-7 {
  margin-right: calc(var(--space) * 7) !important;
  margin-left: calc(var(--space) * 7) !important;
}

.my-7 {
  margin-top: calc(var(--space) * 7) !important;
  margin-bottom: calc(var(--space) * 7) !important;
}

.m-7 {
  margin: calc(var(--space) * 7) !important;
}

.px-7 {
  padding-right: calc(var(--space) * 7) !important;
  padding-left: calc(var(--space) * 7) !important;
}

.py-7 {
  padding-top: calc(var(--space) * 7) !important;
  padding-bottom: calc(var(--space) * 7) !important;
}

.p-7 {
  padding: calc(var(--space) * 7) !important;
}

.mt-8 {
  margin-top: calc(var(--space) * 8) !important;
}

.pt-8 {
  padding-top: calc(var(--space) * 8) !important;
}

.mr-8 {
  margin-right: calc(var(--space) * 8) !important;
}

.pr-8 {
  padding-right: calc(var(--space) * 8) !important;
}

.mb-8 {
  margin-bottom: calc(var(--space) * 8) !important;
}

.pb-8 {
  padding-bottom: calc(var(--space) * 8) !important;
}

.ml-8 {
  margin-left: calc(var(--space) * 8) !important;
}

.pl-8 {
  padding-left: calc(var(--space) * 8) !important;
}

.mx-8 {
  margin-right: calc(var(--space) * 8) !important;
  margin-left: calc(var(--space) * 8) !important;
}

.my-8 {
  margin-top: calc(var(--space) * 8) !important;
  margin-bottom: calc(var(--space) * 8) !important;
}

.m-8 {
  margin: calc(var(--space) * 8) !important;
}

.px-8 {
  padding-right: calc(var(--space) * 8) !important;
  padding-left: calc(var(--space) * 8) !important;
}

.py-8 {
  padding-top: calc(var(--space) * 8) !important;
  padding-bottom: calc(var(--space) * 8) !important;
}

.p-8 {
  padding: calc(var(--space) * 8) !important;
}

.mt-9 {
  margin-top: calc(var(--space) * 9) !important;
}

.pt-9 {
  padding-top: calc(var(--space) * 9) !important;
}

.mr-9 {
  margin-right: calc(var(--space) * 9) !important;
}

.pr-9 {
  padding-right: calc(var(--space) * 9) !important;
}

.mb-9 {
  margin-bottom: calc(var(--space) * 9) !important;
}

.pb-9 {
  padding-bottom: calc(var(--space) * 9) !important;
}

.ml-9 {
  margin-left: calc(var(--space) * 9) !important;
}

.pl-9 {
  padding-left: calc(var(--space) * 9) !important;
}

.mx-9 {
  margin-right: calc(var(--space) * 9) !important;
  margin-left: calc(var(--space) * 9) !important;
}

.my-9 {
  margin-top: calc(var(--space) * 9) !important;
  margin-bottom: calc(var(--space) * 9) !important;
}

.m-9 {
  margin: calc(var(--space) * 9) !important;
}

.px-9 {
  padding-right: calc(var(--space) * 9) !important;
  padding-left: calc(var(--space) * 9) !important;
}

.py-9 {
  padding-top: calc(var(--space) * 9) !important;
  padding-bottom: calc(var(--space) * 9) !important;
}

.p-9 {
  padding: calc(var(--space) * 9) !important;
}

.mt-10 {
  margin-top: calc(var(--space) * 10) !important;
}

.pt-10 {
  padding-top: calc(var(--space) * 10) !important;
}

.mr-10 {
  margin-right: calc(var(--space) * 10) !important;
}

.pr-10 {
  padding-right: calc(var(--space) * 10) !important;
}

.mb-10 {
  margin-bottom: calc(var(--space) * 10) !important;
}

.pb-10 {
  padding-bottom: calc(var(--space) * 10) !important;
}

.ml-10 {
  margin-left: calc(var(--space) * 10) !important;
}

.pl-10 {
  padding-left: calc(var(--space) * 10) !important;
}

.mx-10 {
  margin-right: calc(var(--space) * 10) !important;
  margin-left: calc(var(--space) * 10) !important;
}

.my-10 {
  margin-top: calc(var(--space) * 10) !important;
  margin-bottom: calc(var(--space) * 10) !important;
}

.m-10 {
  margin: calc(var(--space) * 10) !important;
}

.px-10 {
  padding-right: calc(var(--space) * 10) !important;
  padding-left: calc(var(--space) * 10) !important;
}

.py-10 {
  padding-top: calc(var(--space) * 10) !important;
  padding-bottom: calc(var(--space) * 10) !important;
}

.p-10 {
  padding: calc(var(--space) * 10) !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.primary--text {
  color: var(--primary);
}

.linkedin-blue {
  color: var(--default-color);
}

.linkedin-primary {
  color: var(--primary);
}

