.features {
  padding-top: 83px;
  background-color: #fff;
  border-radius: 0 200px 0 0;
  box-shadow: 0px 4px 4px 0px #00000040, 0px 4px 54px 0px #5757574d;
}

.features__container {
  overflow: hidden;
}

.features__title {
  position: relative;
  margin-bottom: 75px;
  padding-left: 90px;
  font-family: var(--font-secondary);
  font-size: 48px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: var(--default-color);
}

.features__title-icon {
  position: absolute;
  top: -5px;
  left: 0;
}

.features__content {
  position: relative;
  max-width: 1258px;
  margin: 0 auto;
}

.features__content-in {
  position: relative;
  z-index: 5;
  padding: 24px 36px 0 36px;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  border: 1px solid #e5e7ec;
  border-bottom: none;
  box-shadow: 0px 0px 0px 4px #f3f4f7, 0px 0px 0px 1px #e4e5e999,
    0px 0px 0px 0.5px #e4e5e9;
}

.features__content:before {
  content: '';
  position: absolute;
  top: 84px;
  left: -22px;
  right: 0;
  bottom: 0;
  width: 96%;
  background: linear-gradient(
    137.95deg,
    #7a96ac 2.28%,
    #eaeff3 19.8%,
    #c2d4e1 32.94%,
    #ffffff 50.16%,
    #d4dee5 62.15%,
    #abbdc8 78.69%,
    #bccad7 95.24%
  );
  box-shadow: 0 0 0 4px #f3f4f7, 0 0 0 1px #e4e5e999, 0 0 0 0.5px #e4e5e9;
  border-radius: 24px;
  transform: rotate(-6.51deg);
  transform-origin: 0 0;
}

.features__content:after {
  content: '';
  position: absolute;
  top: 30px;
  left: 16px;
  right: 0;
  bottom: 0;
  width: 95%;
  background: #0a1e4c;
  box-shadow: 0 0 0 4px #f3f4f7, 0 0 0 1px #e4e5e999, 0 0 0 0.5px #e4e5e9;
  border-radius: 24px;
  transform: rotate(-2.47deg);
  transform-origin: 0 0;
}

.features__subtitle {
  color: var(--default-color);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.2px;
  transition: color 0.25s ease-in-out;
}

.features__icon {
  width: 100%;
  height: 100%;
}

.features__animated-icon {
  width: 26px;
  height: 26px;
  margin-right: 7px;
}

.features__animated-icon svg {
  display: block;
}

.features__animated-icon svg path {
  stroke: var(--default-color);
  fill: var(--default-color);
  transition: stroke 0.25s ease-in-out, fill 0.25s ease-in-out;
}

.features__item {
  cursor: default;
}

.features__item p {
  margin-bottom: 0;
}

.features__item--animate .features__animated-icon svg path {
  stroke: var(--primary);
  fill: var(--primary);
}

.features__item--animate .features__subtitle {
  color: var(--primary);
}

.features__item--animate .features__text {
  opacity: 1;
}

.features__text {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0;
  color: #0a1e4c;
  opacity: 0.6;
  transition: opacity 0.25s ease-in-out;
}

.features__footer {
  margin-top: 22px;
}

.features__image {
  position: relative;
  box-shadow: 0px 4px 14px 0px rgba(10, 30, 76, 0.25);
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 24px;
  overflow: hidden;
  max-width: 1046px;
  margin: auto;
}

.features__image video {
  background-color: white;
  width: 100%;
  position: relative;
  display: block;
  max-width: 100%;
  width: 100%;
}

.features__item:nth-child(1) {
  .features__image {
    display: none;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 844px) {
  .features {
    padding-top: 37px;
    border-radius: 0 50px 0 0;
  }

  .features__title {
    margin-bottom: 73px;
    padding-left: 0;
    font-size: 24px;
  }

  .features__title-icon {
    position: relative;
    display: block;
    width: 36px;
    height: 36px;
    margin-bottom: 13px;
  }

  .features__content-in {
    padding: 24px 12px 0 12px;
  }

  .features__content-in .row {
    flex-direction: column;
  }

  .features__content-in .row .col-4 {
    width: 100%;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  .features__content:before {
    top: -34px;
    height: 20%;
    left: 12px;
    transform: rotate(6.51deg);
  }

  .features__content:after {
    top: -20px;
    height: 50%;
    transform: rotate(2.47deg);
  }

  .features__text {
    font-size: 18px;
    font-weight: 600;
  }

  .features__text br {
    display: none;
  }

  .features__container {
    padding-bottom: 36px;
  }

  .features__footer {
    display: none;
  }

  .features__item:nth-child(1) {
    .features__image {
      margin-top: 4px;
      display: block;
    }
  }

  .features__image-wrapper {
    padding: 4px;
  }

  .features__image {
    border: 1px solid rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 4px 0px rgba(10, 30, 76, 0.25);
    border-radius: 8px;
  }
}
