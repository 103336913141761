.root {
  padding-top: 24px;
}

.grid {
  display: flex;
}

.left {
  flex: 1;
  padding-right: 40px;
  max-width: 325px;
  margin-top: -80px;
}

.stickyWrapper {
  padding-top: 80px;
  margin: 0 -10px;
}

.stickyNavigation {
}

.stickyScroller {
  max-height: calc(100vh - 80px);
  overflow: auto;
  padding: 0 4px;

  nav {
    max-width: 222px;
  }
}

.right {
  flex: 1;
  width: 100%;
}

@media screen and (max-width: 844px) {
  .grid {
    flex-direction: column;
  }

  .left {
    padding-bottom: 48px;

    > div {
      min-height: auto !important;
    }
  }

  .stickyNavigation {
    position: relative !important;
    top: 0 !important;
    transform: none !important;
  }

  .stickyScroller {
    max-height: initial;
    overflow: hidden;
  }
}
