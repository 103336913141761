.root {
  padding-top: 48px;
}

.header {
  text-align: center;
  padding-bottom: 60px;
}

.heading {
  margin-bottom: 0;
  line-height: 2;
}

.grid {
  display: flex;
}

.left {
  flex: 1;
  width: 100%;
  padding-right: 60px;
}

.right {
  flex: 1;
  width: 100%;
  max-width: 490px;
  margin-top: -100px;
}

.block {
  display: block;
}

.faqItem {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 48px;
  color: var(--surface-900);

  h2 {
    color: var(--default-color);
  }

  p {
    margin-top: 12px;
    margin-bottom: 0;
  }

  a {
    color: inherit;
  }
}

.cardWrapper {
  padding-top: 100px;
}

.cardContent {
  padding: 24px 0;
}

.cardHeading {
  padding: 0 36px;
  text-align: center;
}

.form {
  padding: 36px 36px 24px 36px;
}

.formGroup {
  margin-bottom: 18px;
}

.submitButton {
  margin-top: 18px;
  width: 100%;
}

textarea.question {
  min-height: 80px;
}

@media screen and (max-height: 620px), screen and (max-width: 1000px) {
  .stickyForm {
    position: relative !important;
  }
}

@media screen and (max-width: 1320px) {
  .block {
    display: inline;
  }
}

@media screen and (max-width: 1000px) {
  .root {
    padding-top: 30px;
  }

  .header {
    padding-bottom: 48px;
  }

  .heading {
    line-height: 1.2;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  .grid {
    flex-direction: column;
  }

  .left {
    padding: 0;
  }

  .right {
    max-width: initial;
    margin-top: 0;
  }

  .faqItem {
    font-size: 18px;
    margin-bottom: 36px;
  }

  .cardWrapper {
    padding-top: 0;
  }

  .cardHeading {
    padding: 0 24px;
  }

  .formGroup {
    margin-bottom: 12px;
  }

  .form {
    padding: 24px 24px 0;
  }

  .submitButton {
    margin-top: 24px;
  }
}
