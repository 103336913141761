@import '~/src/vars.scss';

.root {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.submitButton {
  margin-top: 12px;
  width: 100%;
}

.grid {
  display: flex;
}

.left {
  flex: 1;
  width: 100%;
}

.right {
  flex: 1;
  width: 100%;
  max-width: 510px;
}

.heading {
  line-height: 2;
  margin-bottom: 12px;
}

.founders {
  display: flex;
  justify-content: flex-start;
  margin: 0 -12px 25px -12px;
}

.founder {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;
}

.founderImage {
  max-width: 150px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 14px 0
    transparentize($color: $landing-default-color, $amount: 0.88);
}

.founderTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.founderPosition {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 500;
}

.linkedin {
  display: block;
  width: 18px;
  height: 18px;
  margin-left: 4px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.contacts {
  ul {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      display: flex;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      > span:nth-child(1) {
        svg {
          width: 24px;
          height: 24px;
          display: block;
        }
      }

      > span:nth-child(2) {
        padding-left: 12px;
      }

      &:nth-child(1) {
        > span:nth-child(1) {
          svg {
            height: 38px;
          }
        }
      }
    }
  }
}

.phoneNumbers,
.emails {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;

    &,
    &:visited,
    &:hover,
    &:focus {
      color: var(--default-color);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.emails {
  a {
    text-decoration: underline;
  }
}

.getInTouchForm {
  padding: 12px 36px 13px 36px;

  :global {
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 1;
    }
  }
}

.formGroup {
  .case {
    min-height: 80px;
  }
}

.formGroup {
  margin-bottom: 4px;
}

.cardHeading {
  font-family: var(--font-secondary);
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--default-color);
  text-align: center;
  padding: 12px 32px 8px 32px;
}

@media screen and (min-width: 480px) {
  .submitButton {
    :global {
      .button__border-gradient {
        &:before {
          transform: translateY(-50%) scaleY(0.15);
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .grid {
    flex-direction: column;
  }

  .cardHeading {
    background-color: #d2def9;
    line-height: 1.6;
    margin-bottom: 0;
  }

  .right {
    padding-top: 60px;
    max-width: initial;
  }

  .founder {
    flex: 1;
  }

  .founderTitle {
    font-size: 18px;
  }

  .founderPosition {
    font-size: 16px;
    white-space: nowrap;
  }

  .linkedin {
    margin-left: 4px;
  }

  .contacts {
    br {
      display: none;
    }
  }
}

@media screen and (max-width: 360px) {
  .founderPosition {
    white-space: initial;
  }
}
