.cta {
  padding: 255px 0 96px 0;
}

.cta-wrapper {
  position: relative;
}

.cta-wrapper canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.cta__in {
  max-width: 480px;
  margin: 0 auto;
  text-align: center;

  .button {
    width: 100%;
    max-width: 292px;
  }
}

.cta__title {
  display: none;
  margin-bottom: 48px;
  color: var(--default-color);
  font-family: var(--font-secondary);
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.cta-wrapper--canvas-error .cta {
  padding-top: 95px;
}

.cta-wrapper--canvas-error .cta__title {
  display: block;
}

.cta .button.button--primary.button--size-l {
  box-shadow: 0px 9px 18px -4.5px rgba(255, 98, 65, 0.78);
}
.cta .button.button--primary.button--size-l:hover,
.cta .button.button--primary.button--size-l:focus {
  box-shadow: 0px 9px 40px -4.5px rgba(255, 98, 65, 0.78);
}

@media screen and (max-width: 844px) {
  .cta {
    padding: 48px 34px !important;
  }

  .cta__in {
    .button {
      width: 100%;
      max-width: 219px;
    }
  }

  .cta__title {
    display: block;
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 28px;
  }

  .cta .button.button--primary.button--size-l .button__content {
    font-size: 16px;
  }

  .cta__canvas {
    display: none;
  }
}
