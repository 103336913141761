.modal {
  --bs-modal-width: 616px;
  --bs-modal-border-radius: 24px;
  --bs-modal-y-padding: 12px;
  --bs-modal-x-padding: 36px;
}

.header {
  justify-content: center;
  flex-direction: column;
  padding: var(--bs-modal-y-padding) var(--bs-modal-x-padding);
  border-bottom: none;
}

.title {
  color: var(--Gray-900, #171717);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
}

.body {
  padding: var(--bs-modal-y-padding) var(--bs-modal-x-padding);
}

.demoBody {
  padding-top: 24px;
  padding-bottom: 12px;
}

.footer {
  padding: var(--bs-modal-y-padding) var(--bs-modal-x-padding);
  border-top: none;
}

.notifyTitle {
  color: var(--secondary-color, #0a1e4c);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}

.okayButton {
  margin-top: 21px;
  width: 100%;
}

@media screen and (max-width: 844px) {
  .modal {
    --bs-modal-margin: 24px;
    --bs-modal-y-padding: 8px;
    --bs-modal-x-padding: 24px;
  }

  .title {
    padding-top: 0;
    font-size: 24px;
  }

  .demoBody {
    padding-top: 16px;
    padding-bottom: 20px;
  }

  .notifyTitle {
    text-align: center;
    font-size: 18px;
  }
}
