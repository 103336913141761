$card-border-radius: 24px;

.root {
  border-radius: $card-border-radius;
  position: relative;
  z-index: 0;
}

.content {
  border-radius: $card-border-radius;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.gradientBorder {
  box-shadow: 0 0 0 0.5px rgba(228, 229, 233, 1),
    0 0 0 1px rgba(228, 229, 233, 0.6), 0 0 0 4px rgba(243, 244, 247, 1);
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: $card-border-radius;
  overflow: hidden;
  pointer-events: none;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -10px;
    background-image: linear-gradient(
      137.95deg,
      #7a96ac 2.28%,
      #eaeff3 19.8%,
      #c2d4e1 32.94%,
      #ffffff 50.16%,
      #d4dee5 62.15%,
      #abbdc8 78.69%,
      #bccad7 95.24%
    );
  }
}

.backCard1 {
  border-radius: $card-border-radius;
  background-color: var(--default-color);
  position: absolute;
  top: -4px;
  left: 17px;
  bottom: 34px;
  right: -5px;
  transform: rotate(-1.24deg) skewX(1.25deg) skewY(-0.2deg);
  transform-origin: top left;
  pointer-events: none;
}

.backCard2 {
  border-radius: $card-border-radius;
  position: absolute;
  top: -4px;
  left: 48px;
  bottom: 34px;
  right: 0;
  transform: rotate(-1.24deg) skewX(1.25deg) skewY(-2deg);
  transform-origin: top left;
  opacity: 0.8;
  pointer-events: none;

  background-image: linear-gradient(
    137.95deg,
    #7a96ac 00.28%,
    #eaeff3 29.8%,
    #c2d4e1 32.94%,
    #ffffff 50.16%,
    #d4dee5 72.15%,
    #abbdc8 88.69%,
    #bccad7 105.24%
  );

  div {
    border-radius: inherit;
    position: absolute;
    display: block;
    content: '';
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: -1;

    background-image: linear-gradient(
      137.95deg,
      #7a96ac 2.28%,
      #eaeff3 19.8%,
      #c2d4e1 32.94%,
      #ffffff 50.16%,
      #d4dee5 62.15%,
      #abbdc8 78.69%,
      #bccad7 95.24%
    );
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--default-color);
    border-radius: inherit;
    opacity: 0.5;
    mix-blend-mode: color;
  }
}

.variant-outline {
  .gradientBorder {
    box-shadow: none;
  }
}

@media screen and (max-width: 1000px) {
  .backCard1 {
    top: -8px;
    right: -15px;
    transform: rotate(-0deg) skewX(-1.8deg) skewY(-0.85deg);
  }

  .backCard2 {
    border-radius: #{$card-border-radius * 1.2};
    top: -10px;
    left: -7px;
    right: 5px;
    transform: rotate(-2deg) skewX(-0.75deg) skewY(-1.5deg);
    opacity: 0.7;
  }
}
