.root {
  &:global(.modal) {
    z-index: 49;
  }

  :global {
    .modal-content {
      padding-top: 70px;
    }

    .modal-body {
      padding: 36px 0;
    }
  }
}

@media screen and (max-width: 844px) {
  .root {
    display: none;
  }
}
