.button {
  display: inline-flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 3px;
  font-family: var(--font);
  color: transparent;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 18px;
  font-weight: 700;
  vertical-align: middle;
  text-transform: none;
  letter-spacing: normal;
  font-stretch: normal;
  white-space: nowrap;
  line-height: 1;
  position: relative;
  border-radius: 11px;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
    color 0.1s ease-in-out, transform 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.button__border-gradient {
  display: block;
  height: 0;
  padding-top: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.button__border-gradient:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  height: 100%;
  opacity: 1;
  z-index: 0;

  transform: translateY(-50%) scaleY(0.3);
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
    color 0.1s ease-in-out, transform 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
}

.button__border-inner {
  position: absolute;
  overflow: hidden;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  border-radius: 10px;
}

.button__border-outer {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 11px;
}

.button__border-outer .button__border-gradient:before {
  background-color: #fff;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(217, 226, 232, 0.8) 0deg,
    rgba(251, 251, 253, 0.8) 26deg,
    rgba(200, 212, 218, 0.8) 90deg,
    rgba(255, 255, 255, 0.8) 155deg,
    rgba(174, 192, 206, 0.8) 190deg,
    rgba(227, 233, 238, 0.8) 235deg,
    rgba(250, 251, 252, 0.8) 255deg,
    rgba(214, 223, 230, 0.8) 311deg,
    rgba(184, 201, 211, 0.8) 335deg,
    rgba(217, 226, 232, 0.8) 360deg
  );
}

.button__border-inner .button__border-gradient:before {
  background-color: #fff;
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(184, 201, 231, 0.8) 0deg,
    rgba(251, 251, 245, 0.8) 26deg,
    rgba(200, 212, 228, 0.8) 90deg,
    rgba(255, 255, 245, 0.8) 155deg,
    rgba(174, 192, 226, 0.8) 190deg,
    rgba(227, 233, 255, 0.8) 235deg,
    rgba(250, 251, 255, 0.8) 255deg,
    rgba(214, 223, 250, 0.8) 311deg,
    rgba(184, 201, 231, 0.8) 335deg,
    rgba(184, 201, 231, 0.8) 360deg
  );
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: 0 calc((var(--space) * 1.5) - 1px);
  transform-origin: center;
  transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out,
    color 0.1s ease-in-out, transform 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  border-radius: 9px;
}

.button__content-in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.button--primary {
  box-shadow: 0px 4px 16px 0px rgba(194, 12, 110, 0.2);
}

.button--primary .button__content {
  color: #fff;
  background: var(--primary--button-bg);
  box-shadow: 0px 6px 12px -3px rgba(255, 98, 65, 0.78) inset;
}

.button--primary:hover,
.button--primary:focus {
  box-shadow: 0px 12px 22px -5px rgba(194, 12, 110, 0.2);
}

.button--primary:hover .button__content,
.button--primary:focus .button__content {
  background: var(--primary--button-hover-bg);
}

.button--secondary .button__content {
  color: #fff;
  background: var(--default-color);
}

.button--secondary:hover .button__content,
.button--secondary:focus .button__content {
  background: var(--default-color-hover);
}

.button--size-s {
  height: 48px;
}

.button--size-s .button__content {
  padding: 0 calc(var(--space) * 10);
}

.button--size-xs {
  height: 48px;
}

.button--size-xs .button__content {
  padding: 0 calc(var(--space) * 10);
}

.button--size-m {
  height: 66px;
}

.button--size-m .button__content {
  padding: 0 33px;
  font-family: var(--font-secondary);
  font-size: 18px;
  border-radius: 9px;
  font-weight: 400;
}

.button--size-l {
  height: 80px;
  padding: 4px;
  border-radius: 16.5px;
}

.button--size-l .button__border-outer {
  border-radius: 16.5px;
}

.button--size-l .button__border-inner {
  border-radius: 15.5px;
}

.button--size-l .button__content {
  padding: 0 27px;
  font-family: var(--font-secondary);
  font-size: 27px;
  font-weight: 400;
  border-radius: 13.5px;
}

.button__append {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  display: block;
  /* margin-top: -3px; */
}

.button--border-2 {
  padding: 2px;
}

.button--border-2 .button__border-inner {
  display: none;
}

@media screen and (max-width: 844px) {
  .button--size-m .button__content {
    font-size: 16px;
  }

  .button--size-m {
    height: 52px;
    padding: 2px;
  }

  .button--size-l {
    border-radius: 9px;
    padding: 2px;
    height: 52px;
  }

  .button--size-l {
    border-radius: 11px;
    padding: 2px;
    height: 52px;
  }

  .button--size-l .button__border-outer {
    border-radius: 11px;
  }

  .button--size-l .button__border-inner {
    border-radius: 10px;
  }

  .button--size-l .button__content {
    border-radius: 9px;
  }

  .button--size-xs {
    padding: 1px;
    height: 36px;
    font-size: 16px;
    line-height: 1.2;
    border-radius: 6px;
  }

  .button--size-l .button__border-outer {
    border-radius: 6px;
  }

  .button--size-l .button__border-inner {
    display: none;
  }

  .button--size-xs .button__content {
    border-radius: 6px;
  }
}
