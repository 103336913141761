.root {
  margin-left: auto;
  margin-right: -12px;
  margin-bottom: -25px;
  margin-top: 6px;

  svg {
    display: block;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    opacity: 0.5;
  }

  &:hover {
    svg {
      transform: rotate(-90deg);
      opacity: 1;

      path {
        stroke: var(--default-color-hover);
      }
    }
  }

  @media screen and (max-width: 844px) {
    margin-top: 0;
    margin-bottom: 0;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
