.root {
  max-width: 325px;

  a {
    color: var(--surface-900);
    display: block;
    padding: 6px 0;
    line-height: 1.2;
    letter-spacing: -0.01em;
    transition: 0.25s ease-in-out;

    &:hover {
      color: var(--default-color);
    }

    &:not(:hover) {
      text-decoration: none;
    }
  }

  :global {
    .accordion {
      --bs-accordion-border-width: 0;
      --bs-accordion-border-radius: 0;
      --bs-accordion-active-bg: initial;
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00028 9.37503C7.82784 9.37584 7.65696 9.34221 7.49769 9.2761C7.33842 9.20998 7.19396 9.11272 7.07278 8.99003L1.00028 2.86503C0.840348 2.61835 0.767861 2.32513 0.794439 2.03234C0.821017 1.73956 0.945121 1.46418 1.14686 1.25034C1.3486 1.0365 1.61629 0.896574 1.90703 0.853C2.19777 0.809426 2.49472 0.864724 2.75028 1.01003L8.00028 6.26003L13.2503 1.01003C13.5058 0.864724 13.8028 0.809426 14.0935 0.853C14.3843 0.896574 14.652 1.0365 14.8537 1.25034C15.0554 1.46418 15.1795 1.73956 15.2061 2.03234C15.2327 2.32513 15.1602 2.61835 15.0003 2.86503L8.87528 8.99003C8.64339 9.22526 8.33038 9.36298 8.00028 9.37503V9.37503Z' fill='%23FF5C29'/%3E%3C/svg%3E%0A");
      --bs-accordion-btn-active-icon: var(--bs-accordion-btn-icon);
      --bs-accordion-inner-border-radius: 0.5rem;
      --bs-accordion-btn-icon-width: 0.9rem;

      &-header {
        padding: 4px 0;
      }

      &-button {
        font-size: 2rem;
        line-height: 2;
        color: var(--primary);
        padding: 0;
        border-radius: var(--bs-accordion-inner-border-radius) !important;

        &::after {
          margin: 0 0.875rem;
        }
      }

      &-body {
        padding: 0;
        font-size: 1.125rem;

        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          font-weight: 600;
        }
      }
    }
  }
}

.active {
  a {
    color: var(--primary);

    &:hover {
      color: var(--primary);
    }
  }
}

@media screen and (max-width: 844px) {
  .root {
    :global {
      .accordion {
        &-button {
          font-size: 1.5rem;
          line-height: 1.42;
        }
      }
    }
  }
}
