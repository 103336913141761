.root {
  position: relative;
}

.isLoadingText {
  opacity: 0;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .spinner-border {
      color: var(--primary) !important;
    }
  }
}

.root:global(.button--primary) {
  :global {
    .spinner-border {
      color: #ffffff !important;
    }
  }
}
