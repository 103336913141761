.benefits {
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-top: -70px;
  padding: 48px 0 72px 0;
  border-radius: 160px 0 160px 0;
  background-blend-mode: lighten;
  background-color: #ffe5cd;
  box-shadow: 0 0 54px 0 #5757573b;
}

.benefits:before {
  content: '';
  display: block;
  background-color: #bfdbef;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

.benefits--animate:before {
  animation: benefits-animation 7s infinite;
}

.benefits .container {
  position: relative;
}

.benefits__title {
  max-width: 600px;
  margin-bottom: 26px;
  font-family: var(--font-secondary);
  font-size: 42px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.benefits__title-text {
}

.benefits__title-text:nth-child(2) {
  color: var(--default-color);
  text-align: right;
}

.benefits__title span:last-child {
  color: var(--default-color);
  padding-left: 99px;
}

.benefits__row {
  padding: 24px 0;
  border-bottom: 2px solid #fff;
}

.benefits__content .benefits__row:last-child {
  border-bottom: none;
}

.benefits__subtitle {
  position: relative;
  display: flex;
  align-items: center;
  font-family: var(--font-secondary);
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.03em;
  color: var(--primary);
}

.benefits__text {
  color: #0a1e4c;
  font-family: var(--font);
  list-style: none;
  font-weight: 600;
  padding: 0;
  font-family: 'Darker Grotesque';
  font-size: 24px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: var(--default-color);

  li {
    position: relative;
    padding-left: 16px;
    display: flex;
    align-items: baseline;
  }

  li:before {
    content: '';
    display: block;
    background-color: var(--default-color);
    width: 3px;
    height: 3px;
    border-radius: 6px;
    position: absolute;
    left: 0;
    top: 0.725em;
  }
}

.benefits__icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  margin-right: 6px;
}

@media screen and (max-width: 844px) {
  .benefits {
    margin-top: -50px;
    border-radius: 50px 0 50px 0;
    padding: 36px 0 24px 0;
  }

  .benefits__title {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .benefits__title-text:nth-child(2) {
    text-align: left;
  }

  .benefits__title span:last-child {
    padding-left: 0;
  }

  .benefits__row:first-child {
    padding-top: 0;
  }

  .benefits__row .row {
    flex-direction: column;
  }

  .benefits__row .col {
    width: 100%;
    box-sizing: border-box;
  }

  .benefits__subtitle {
    margin-bottom: 12px;
    font-size: 24px;
  }

  .benefits__text {
    padding-left: 10px;
    font-size: 18px;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 0;
  }

  .benefits__title-row {
    .col-1 {
      display: none;
    }

    .col-11 {
      width: 100%;
    }
  }

  .benefits__text-item--no-wrap {
    white-space: nowrap;
  }
}

@media screen and (max-width: 364px) {
  .benefits__text-item--no-wrap {
    white-space: initial;
  }
}

@keyframes benefits-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
