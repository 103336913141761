.main-menu__list {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.main-menu__item {
  margin-left: 20px;
}

.main-menu__item:first-child {
  margin-left: 0;
}

.main-menu__link {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--default-color);
  transition: color 0.25s ease-in-out;

  &:hover {
    color: var(--primary);
  }
}

@media screen and (max-width: 844px) {
  .main-menu__list {
    flex-direction: column;
  }

  .main-menu__item {
    margin-left: 0;
  }

  .main-menu__link {
    font-size: 24px;
    padding: 12px 24px;
    display: block;
    line-height: 1.2;
  }
}
