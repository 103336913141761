.section-anchor-root {
  position: relative;
}

.section-anchor {
  height: 100%;
  position: absolute;
  /* header height */
  top: -90px;
  bottom: 0;
  width: 100%;
  pointer-events: none;
}
