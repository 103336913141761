.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

.header {
  background-color: white;
  box-shadow: 0 0 0 0 #fff, 0 0 0 0 #57575733;
  transition: box-shadow 0.25s ease-in-out;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 50;
}

.stickyHeader {
  background-color: white;
  box-shadow: 0 -100px 0 0 #fff, 0 4px 30px 0 #57575733;
}

.navigationOpen {
  box-shadow: 0 0 0 0 #fff, 0 0 0 0 #57575733;
}
